import { useCallback, useState } from 'react';
import moment from "moment-timezone";
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import MUTATION_UPDATE_CART_ITEM from '../../../aem-core-components/queries/mutation_update_cart_item.graphql';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { useCartState } from '../../../contexts/cart';
import useCartAnalytics from '../../../hooks/analytics/useCartAnalytics';
import useCartOptions from '../../../hooks/useCartOptions';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { useChronosAvailability } from '../../../hooks/useChronosAvailability';
import useProduct from '../../../hooks/useProduct';
import useUserData from '../../../hooks/useUserData';
import useCartEstimate from '../../App/hooks/useCartEstimate';
import useCheckoutNavigate from '../../App/hooks/useCheckoutNavigate';
import { useFilterState } from '../../cap';
import { useAtp } from '../../cap/hooks/useATP';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { getDateDiffInHrs } from '../../cap/utils/atputils';
import {
    checkIsEditQuoteFlow,
    generateSKU,
    isTier2Radius,
    redirectToCheckoutOrQuotePage
} from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { AUTHORITY_TYPE, EMPLOYEE } from '../../global/constants';
import { TILE_STATES } from '../../global/modules/productTile/constants';
import {
    SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
    SET_CART_ITEM_STATUS_CHANGED_REASON,
    SET_CHECKOUT_ERROR_DETAILS,
    SET_IS_CART_LOADING,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_SOURCES_LOADING_FLAG,
    SET_UNAVAILABLE_CART_ITEMS_PER_PC,
    SET_VIEW_CART_FIELDS
} from '../../../aem-core-components/actions/constants';
import { VIEW_CART } from '../../../constants/cartConstants';

/**
 * @class useCartOperations - is a custom hook that provides various utility functions to handle cart operations .
 * @param {item} props.item = cart item on which operation has to be performed @default {}
 * @returns {Object} An object containing various utility functions.
 */
export const useCartOperations = props => {
    const { item } = props || {};
    const cartIntl = useIntl();
    const [, { removeItem }] = useProduct({ item });
    const { handleATPCart, getItemAvailabilityATP } = useAtp();
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const [, { dispatch, updateCartFromMinicart, removeUnavailableItems }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery,
        handleATPCart
    });
    const [{ cart, cartId, isCreditNewAddress, isSourcesLoading, userAccount, unavailableCartItemsPerPc }] =
        useCartState();
    const { sendEventsForEcommerceRemove, sendEventsForEcommerceAdd, sendEventsForEcommerceAction } =
        useAnalyticsContext();
    const [{ getProductPayload, payloadEcommerceCartItemAnalytics }] = useCartAnalytics();
    const [{ startDate, endDate, viewCart, selectedStoreDetails, projectDetails, pickupStores }, filterDispatch] = useFilterState();
    const [{ userProfile }, { dispatch: userDispatch }] = useUserContext();
    const [{ updateFourHourRentals }] = useUserData();
    const { updateBsrPricingPCs, getSortedStoreList } = useCapUtils();
    const { isSelectedLocationEmpty, isRentalDetailsAvailable } = useCheckLocationEmpty();
    const [{ getEstimates }] = useCartEstimate();
    const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
    const isEditQuoteFlow = checkIsEditQuoteFlow();
    const {
        getMergedSourcesATPStores,
        getAvailableProducts,
        getItemAvailabilityATPChronos,
        getStoresWithUnvailableProducts
    } = useChronosAvailability();
    const { getChronosStores, computeChronosStoreWithAVS, validateDateSlots } = useCheckoutNavigate();
    const [sortedChronosPcs, setSortedChronosPcs] = useState(null);
    const [isCheckoutDetailsValidating, setIsCheckoutDetailsValidating] = useState(false);
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isAtpDisabled = rollbackToCi || false;
    const { rentallabels = '{}' } = ENV_CONFIG.CAP_HEADER || {};
    const userType = useCheckUser();
    const authorityType = useCheckAuthorityType();
    const parsedRentalLabels = JSON.parse(rentallabels);
    const emptyRentalDetailsLabel =
        parsedRentalLabels?.['empty-rental-details'] ||
        cartIntl.formatMessage({ id: 'cart:product-tile-rental-details' });
    const emptyRentalLocationLabel =
        parsedRentalLabels?.['empty-rental-location'] ||
        cartIntl.formatMessage({ id: 'cart:product-tile-rental-location' });
    const emptyRentalDatesLabel =
        parsedRentalLabels?.['empty-rental-dates'] || cartIntl.formatMessage({ id: 'cart:product-tile-rental-dates' });
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const updateUnavailableItemsOnRemove = (cartItems, itemToRemove) => {
        const updatedList = {};
        if (cartItems?.length) {
            const unavailableItemsList = Object.keys(unavailableCartItemsPerPc || {});
            unavailableItemsList?.forEach(store => {
                updatedList[store] = unavailableCartItemsPerPc[store]?.filter(
                    item => item?.sku !== itemToRemove?.product?.sku
                );
            });
        }
        // Once product is removed will sort all stores based on product availability and distance  and update in context
        getSortedStoreList(pickupStores, updatedList, true);
        dispatch({
            type: SET_UNAVAILABLE_CART_ITEMS_PER_PC,
            unavailableCartItemsPerPc: updatedList
        });
    };

    const handleRemoveItem = useCallback(
        async itemToRemove => {
            try {
                sendEventsForEcommerceRemove(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.CART,
                    getProductPayload(itemToRemove?.quantity, itemToRemove)
                );
                sendEventsForEcommerceAction(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_REMOVE_CART_ITEM,
                    payloadEcommerceCartItemAnalytics(itemToRemove, true)
                );
            } catch (error) {
                logError(error, false, 'handleRemoveItem');
            }
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
            const cartItems = await removeItem(itemToRemove?.uid);
            updateUnavailableItemsOnRemove(cartItems, itemToRemove);
            updateFourHourRentals(itemToRemove?.product?.sku, false);
            dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            handleRemoveMergeCartError(itemToRemove);
        },
        [unavailableCartItemsPerPc]
    );

    const handleViewSimilar = item => {
        try {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_SIMILAR_ITEMS,
                payloadEcommerceCartItemAnalytics(item)
            );
        } catch (error) {
            logError(error, false, 'handleViewSimilar');
        }
    };

    const handleRemoveMergeCartError = item => {
        if (userProfile?.skip_qty[item?.product?.sku]) {
            const skuQtyCopy = { ...userProfile?.skip_qty };
            delete skuQtyCopy[item?.product?.sku];
            userDispatch({ type: 'updateSkipQtyObject', skipQtyObj: skuQtyCopy });
        }
    };

    const handleCartQuantityUpdation = useCallback(async (newVal, item) => {
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        await updateCartFromMinicart([{ cart_item_uid: item?.uid, quantity: newVal }]);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        if (newVal - item?.quantity < 0) {
            try {
                sendEventsForEcommerceRemove(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.CART,
                    getProductPayload(Math.abs(newVal - item?.quantity), item)
                );
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        } else {
            try {
                sendEventsForEcommerceAdd(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.CART,
                    getProductPayload(newVal - item?.quantity, item)
                );
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        }
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
    }, []);

    const initialiseSavedCart = () => {
        return getCartDetails({
            cartDetailsQuery,
            dispatch,
            cartId,
            filterDispatch,
            handleATPCart,
            isRentalDetailsAvailable: isRentalDetailsAvailable()
        });
    };

    const renderEmptyRentalLabels = () => {
        if (isValidString(isAtpDisabled) && isSelectedLocationEmpty()) {
            return cartIntl.formatMessage({ id: 'cart:enter-location-for-price' });
        } else if (isSelectedLocationEmpty() && (!startDate || !endDate)) {
            return emptyRentalDetailsLabel;
        } else if (isSelectedLocationEmpty()) {
            return emptyRentalLocationLabel;
        } else if (!startDate || !endDate) {
            return emptyRentalDatesLabel;
        }
    };

    const recomputeEstimates = async (pcVal, pcLat, pcLong, cartItems = cart?.items, signal) => {
        // not calling estimates if dates are missing
        if (pcVal && startDate && endDate && cartItems?.length > 0) {
            const cartEstimates = await getEstimates('', pcVal, pcLat, pcLong, false, cartItems, signal);
            return cartEstimates;
        }
    };

    /**
     * This function is for the scenario when user lands on checkout page after all the checks are performed.
     * This function will fetch cart items from storage and call estimates
     */
    const renderCheckoutWithoutValidations = async (isEstimatesCallRequired = true) => {
        try {
            let cartEstimates = null;
            const cartItems = getItemStatusFromStorageOrATP(cart?.items);
            const conditionalItems = cartItems?.conditionalItems || [];
            const unavailableItems = cartItems?.unavailableItems || [];
            if (viewCart?.pc && cartItems?.availableItems?.length && isEstimatesCallRequired) {
                // estimates call
                cartEstimates = await recomputeEstimates(
                    viewCart?.pc,
                    viewCart?.pcLat,
                    viewCart?.pcLong,
                    cartItems?.availableItems
                );
            }
            const availableItems = cartEstimates?.availableCartItems || cartItems?.availableItems || [];
            dispatch({
                type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
                availableCartItems: availableItems,
                unavailableCartItems: [...unavailableItems, ...conditionalItems]
            });
        } catch (error) {
            logError(error, false, 'renderCheckoutWithUserDetails');
        }
    };

    const fetchCartForCheckout = async () => {
        const viewCartData = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)) || {};
        await updateBsrPricingPCs({ localLat: viewCartData?.lat, localLong: viewCartData?.long });
        const estimatesResponse = await getCartItems();
        const unavailableItems = estimatesResponse?.unavailableCartItems;
        // removing previous unavailable items from cart for override pc case
        if (overridePC?.pc && unavailableItems?.length > 0) {
            await removeUnavailableItems(unavailableItems, false);
        }
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);
    };

    /**
     * This function will fetch cart items availability
     * @returns available, conditional and unavailable items
     */
    const renderCartItems = async () => {
        // resetting checkout errors
        dispatch({ type: SET_CHECKOUT_ERROR_DETAILS });
        await updateBsrPricingPCs();
        const cartDetails = await getCartItemsWithAvailability();
        const conditionalItems = cartDetails?.conditionalItems || [];
        const unavailableItems = cartDetails?.unavailableItems || [];
        const availableItems = cartDetails?.availableItems || [];
        dispatch({
            type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
            availableCartItems: availableItems,
            unavailableCartItems: [...unavailableItems, ...conditionalItems]
        });
        return {
            availableItems,
            conditionalItems,
            unavailableItems
        };
    };

    const getCartItemsWithAvailability = async () => {
        const estimatesResponse = await getCartItems();
        if (estimatesResponse?.items?.length === 0) {
            return {};
        }
        const items = await removeUnavailableItemsForOverridePc(estimatesResponse);
        if (startDate && endDate) {
            return handleDateRange(items);
        } else {
            return handleNoDateRange(items);
        }
    };

    const getCartItems = async () => {
        return (
            cart ||
            (await getCartDetails({
                cartDetailsQuery,
                dispatch,
                cartId,
                filterDispatch,
                handleATPCart,
                isRentalDetailsAvailable: isRentalDetailsAvailable()
            }))
        );
    };

    const removeUnavailableItemsForOverridePc = async estimatesResponse => {
        const discardOldCart =
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART)) || false;
        const unavailableItems = estimatesResponse?.unavailableCartItems;
        let items;
        // removing previous unavailable items from cart for override pc case
        if (discardOldCart && overridePC?.pc && unavailableItems?.length > 0) {
            await removeUnavailableItems(unavailableItems, false);
            items = estimatesResponse?.availableCartItems;
        } else {
            items = estimatesResponse?.items;
        }
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);
        return items;
    };

    const handleDateRange = async (items = []) => {
        const cartItems = getItemStatusFromStorageOrATP(items);
        saveCartItemsInStorage(cartItems);
        if (cartItems?.availableItems?.length && !overridePC?.pc && isRentalDetailsAvailable()) {
            handleChronosStores(cartItems);
        } else {
            setSortedChronosPcs(null);
        }
        return cartItems;
    };

    /**
     * This function will get the item availability status from storage,
     * if status is not present in storage, will fetch it from atp
     * @param {*} items
     * @returns available, conditional and unavailable items
     */
    const getItemStatusFromStorageOrATP = items => {
        const cartItemsFromStorage = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS) || '{}'
        );
        const availableItems = [];
        const conditionalItems = [];
        const unavailableItems = [];
        items?.map(item => {
            const catsku = item?.product?.sku;
            let tileStatus = cartItemsFromStorage[catsku];
            if (!tileStatus) {
                // if status is not in storage, will calculate it from atp
                tileStatus = handleATPCart({
                    catsku,
                    inventoryDataObj: item?.product?.ec_pc_inventory
                });
            }
            if (tileStatus === TILE_STATES.AVAILABLE || tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING) {
                availableItems.push(item);
            } else if (tileStatus === TILE_STATES.UNAVAILABLE) {
                unavailableItems.push(item);
            } else {
                conditionalItems.push({ ...item, status: tileStatus });
            }
        });
        return { availableItems, conditionalItems, unavailableItems };
    };

    /**
     * This function fetches chronos stores to show when guest user selects place an in-store pickup order.
     * Will call chronos with available + conditional items as conditional item can become available when
     * user selects a store from the modal.
     * @param {*} cartItems
     */
    const fetchChronosForInStorePickup = async (availableItems = [], conditionalItems = []) => {
        try {
            const { data: stores } = await getChronosStores({
                availableCartItems: [...availableItems, ...conditionalItems],
                isDelivery: false
            });
            const sortedAvailableStores = getMergedSourcesATPStores(stores?.data, stores?.data);
            return sortedAvailableStores;
        } catch (error) {
            logError(error, false, 'fetchChronosForInStorePickup');
        }
    };

    /**
     * This function calls sources with available items and
     * and calls another sources with available+conditional items.
     * It then selects the stores from 1st call and decided item
     * availability from 2nd sources call.
     * @param {*} cartItems
     */
    const handleChronosStores = async cartItems => {
        try {
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: true
            });
            const { data: stores } = await getChronosStores({ availableCartItems: cartItems?.availableItems });
            const sortedAvailableStores = getMergedSourcesATPStores(stores?.data, stores?.data);
            let chronosStores = sortedAvailableStores;
            /**
             *  will call the 2nd sources even if the 1st sources suggested the selected store
             *  as the 1st sources will not have data for conditional items.
             *  This will be called only for pickup within 72hrs as for other cases we do not
             *  show discrepency drawer, so conditional items data is not required.
             */
            if (
                cartItems?.conditionalItems?.length > 0 &&
                sortedAvailableStores?.length > 0 &&
                viewCart?.isInStorePickup &&
                isWithin72Hours()
            ) {
                const availableItems = cartItems?.availableItems || [];
                const conditionalItems = cartItems?.conditionalItems || [];
                const { data: availableCondtionalStores } = await getChronosStores({
                    availableCartItems: [...availableItems, ...conditionalItems]
                });
                chronosStores = mergeAvailableAndConditionalStores(
                    availableCondtionalStores?.data,
                    sortedAvailableStores,
                    cartItems?.conditionalItems
                );
            }
            setSortedChronosPcs(chronosStores);
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
            return chronosStores;
        } catch (error) {
            logError(error, false, 'handleChronosStores');
        }
    };

    const getConditionalItemsAsSourcesUnavailable = conditionalItems => {
        const unavailableItems = conditionalItems?.map(item => ({
            availableQuantity: 0,
            equipmentCategory: parseInt(item?.product?.sku?.slice(0, 3)),
            equipmentClass: parseInt(item?.product?.sku?.slice(3))
        }));
        return unavailableItems || [];
    };

    const mergeAvailableAndConditionalStores = (availableCondtionalStores, availableItemsStores, conditionalItems) => {
        return availableItemsStores?.map(availableStore => {
            const conditionalStore = availableCondtionalStores?.find(item => item?.pc === availableStore?.pc);
            if (conditionalStore) {
                // if store is present in 2nd sources call, then taking items status from 2nd sources call
                return { ...availableStore, items: conditionalStore?.items };
            } else {
                /** if store is not present in 2nd sources call,
                 *  then taking available items status from 1st sources call
                 *  and making conditional items as unavailable */
                return {
                    ...availableStore,
                    items: [
                        ...(availableStore?.items || []),
                        ...getConditionalItemsAsSourcesUnavailable(conditionalItems)
                    ]
                };
            }
        });
    };

    const showDiscrepencyStoreSelector = availableItems => {
        if (viewCart?.isInStorePickup && sortedChronosPcs?.length > 0) {
            if (isWithin72Hours()) {
                if (sortedChronosPcs[0]?.pc !== selectedStoreDetails?.pc) {
                    return true;
                } else {
                    // this is to check when the chronos suggested the selected item but
                    // some available item may be unavailable from chronos
                    const isItemMissing = availableItems?.find(item => isItemMissingInStore(sortedChronosPcs[0], item));
                    return !!isItemMissing;
                }
            } else {
                const isMatchedPc = sortedChronosPcs?.find(chronosPc => chronosPc?.pc === selectedStoreDetails?.pc);
                return !isMatchedPc;
            }
        }
        return false;
    };

    const isItemMissingInStore = (store, item) => {
        const matchedItem = store?.items?.find(
            chronosItem =>
                generateSKU(chronosItem?.equipmentCategory, chronosItem?.equipmentClass) === item?.product?.sku
        );
        // sometimes available quantity is negative from chronos
        return !matchedItem?.availableQuantity || matchedItem?.availableQuantity <= 0;
    };

    const getCartItemsFromATPAndSources = ({
        chronosStores,
        selectedChronosStore,
        isPickup = viewCart?.isInStorePickup
    }) => {
        try {
            const cartItemsWithATP = getItemAvailabilityATP(cart?.items, isPickup, selectedChronosStore);
            let updatedItems = cartItemsWithATP;
            if (cartItemsWithATP?.availableItems?.length && isWithin72Hours()) {
                const chronosAvailabilityStatus = getAvailableProducts(
                    chronosStores,
                    selectedChronosStore,
                    isWithin72Hours()
                );
                // deciding the item availabilty based on atp + chronos
                updatedItems = getItemAvailabilityATPChronos(cart?.items, cartItemsWithATP, chronosAvailabilityStatus);
            }
            return updatedItems;
        } catch (error) {
            logError(error, false, 'updateItemsFromSelectedChronosStore');
            return {};
        }
    };

    const updateItemsFromSelectedChronosStore = ({ chronosStores = sortedChronosPcs, selectedChronosStore }) => {
        try {
            // getting cart items status from atp
            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS,
                JSON.stringify(selectedChronosStore)
            );
            const updatedItems = getCartItemsFromATPAndSources({ chronosStores, selectedChronosStore });
            saveCartItemsInStorage(updatedItems);
            dispatch({
                type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
                availableCartItems: updatedItems?.availableItems || [],
                unavailableCartItems: [
                    ...(updatedItems?.unavailableItems || []),
                    ...(updatedItems?.conditionalItems || [])
                ]
            });
            return updatedItems;
        } catch (error) {
            logError(error, false, 'updateItemsFromSelectedChronosStore');
            return {};
        }
    };

    const saveCartItemsInStorage = ({ availableItems, conditionalItems, unavailableItems }) => {
        const cartItems = {};
        availableItems?.forEach(item => {
            cartItems[item?.product?.sku] = TILE_STATES.AVAILABLE;
        });
        unavailableItems?.forEach(item => {
            cartItems[item?.product?.sku] = TILE_STATES.UNAVAILABLE;
        });
        conditionalItems?.forEach(item => {
            cartItems[item?.product?.sku] = item?.status;
        });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS, JSON.stringify(cartItems));
    };

    const handleNoDateRange = (items = []) => {
        return getItemAvailabilityATP(items);
    };

    const isWithin72Hours = (date = startDate) => {
        return getDateDiffInHrs(date, moment().format('YYYY-MM-DDTHH:mm:ss')) < 72;
    };

    /**
     * This function adds the name of the products by fetching it from cartItems
     * @param {*} cartItems = To fetch products names
     * @param {*} storesWithUnAvailableProducts = To add product names into the object
     */
    const addItemName = (cartItems, storesWithUnAvailableProducts) => {
        const itemMap = {};
        cartItems?.forEach(item => {
            // creating itemMap lookup to store product name based on its sku
            itemMap[item?.product?.sku] = item?.product?.name;
        });

        storesWithUnAvailableProducts?.forEach(item => {
            item?.unavailableProducts?.forEach(product => {
                if (product) {
                    // adding the product name by fetching from the itemMap lookup
                    product.name = itemMap[product?.catclass];
                }
            });
        });
    };

    /**
     * This function accept cartItems array for fetching product name from catclass
     * It returns the selected store and the rest chronos stores as suggested stores
     * along with the items unavailable at each store.
     * @param {*} unavailableItemsArr
     * @param {*} cartItems
     * @returns
     */
    const getSelectedAndSuggestedStores = (unavailableItemsArr, cartItems) => {
        try {
            const storesWithUnavailableProducts = getStoresWithUnvailableProducts(sortedChronosPcs, isWithin72Hours());
            addItemName(cartItems, storesWithUnavailableProducts);
            const unavailableItems =
                unavailableItemsArr?.map(item => ({
                    catclass: item?.product?.sku,
                    name: item?.product?.name
                })) || [];

            //  adding all the unavailable cart items to the stores existing unavailable products
            storesWithUnavailableProducts?.forEach(items => {
                items?.unavailableProducts?.push(...unavailableItems);
            });
            const index = storesWithUnavailableProducts?.findIndex(
                product => selectedStoreDetails?.pc === product?.store?.pc
            );
            // Check if all cartItems are unavailable in all stores
            const allItemsUnavailableInAllStores = cartItems?.every(cartItem =>
                storesWithUnavailableProducts?.every(store =>
                    store?.unavailableProducts?.some(unavailable => unavailable?.catclass === cartItem?.product?.sku)
                )
            );

            if (index === -1) {
                /**
                 *  if the selected store is not in the sources call
                 *  then, adding all the cart items as unavailable for
                 *  the selected store and returning the chronos stores
                 *  as the suggested stores.
                 */
                return {
                    selectedStore: {
                        store: selectedStoreDetails,
                        unavailableProducts: [...cartItems?.map(items => items?.product)]
                    },
                    suggestedStores: storesWithUnavailableProducts,
                    allItemsUnavailableInAllStores
                };
            } else {
                /**
                 *  if the selected store is in the sources call then,
                 *  sending that store from the chronos store data as the
                 *  selected store and returning the rest chronos stores
                 *  as the suggested stores.
                 */
                return {
                    selectedStore: storesWithUnavailableProducts[index],
                    suggestedStores: [
                        ...(storesWithUnavailableProducts?.slice(0, index) || []),
                        ...(storesWithUnavailableProducts?.slice(index + 1) || [])
                    ],
                    allItemsUnavailableInAllStores
                };
            }
        } catch (err) {
            logError(err, false, 'getSelectedAndSuggestedStores');
            return {};
        }
    };

    const handleChange = (key, value) => {
        try {
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
        } catch (error) {
            logError(error, false, 'handleChange');
        }
    };

    const getChronosStoresForValidation = updatedViewCart => {
        if (sortedChronosPcs) {
            let store;
            if (updatedViewCart?.isInStorePickup && !isValidString(isTier2Radius())) {
                // sending selected store pc from sources for pickup
                const selectedStore = JSON.parse(
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
                );
                store = sortedChronosPcs?.find(store => store?.pc === selectedStore?.pc);
            } else {
                // sending first pc and not the selected store pc for delivery
                store = sortedChronosPcs?.[0];
            }
            return store ? [store] : [];
        }
        // sending null if sources call was not made, so it will be called during validation
        return null;
    };

    const saveUserCartAndProjectData = updatedViewCart => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART, JSON.stringify(updatedViewCart));
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.PROJECTDETAILS, JSON.stringify(projectDetails));
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, isCreditNewAddress);
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING, isSourcesLoading);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CHECKOUTREFERRER, true);
        if (userType === USER_TYPE.CREDIT && isCreditNewAddress) {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE, true);
        } else {
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE);
        }
    };

    const onCheckoutHandler = async ({
        updatedViewCart = { ...viewCart },
        availableCartItems,
        updateEstimatesFromDeliverySourcePc,
        recomputeSourcesData = false
    }) => {
        try {
            saveUserCartAndProjectData(updatedViewCart);
            setIsCheckoutDetailsValidating(true);
            // validationDetails will have isValid value and other error details if validations fail
            const validationDetails = await validateCartDetails({
                chronosStores: recomputeSourcesData ? null : getChronosStoresForValidation(updatedViewCart),
                availableCartItems,
                updateEstimatesFromDeliverySourcePc,
                isDelivery: !updatedViewCart?.isInStorePickup
            });
            setIsCheckoutDetailsValidating(false);
            if (validationDetails?.isValid) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISUSERDETAILSSET, true);
                redirectToCheckoutOrQuotePage(isEditQuoteFlow ? displayQuoteId : '');
            }
            return validationDetails;
        } catch (error) {
            logError(error, false, 'onCheckoutHandler');
            return { isValid: false };
        }
    };

    const getUnavailableDeliveryItemsFromSources = (chronosStores, availableCartItems, isDelivery) => {
        if (!overridePC?.pc && isDelivery && isWithin72Hours()) {
            const unavailableItems = chronosStores?.[0]?.items?.filter(item => item?.availableQuantity <= 0) || [];
            if (unavailableItems?.length === 0) {
                return [];
            }
            const unavailableItemsSku = unavailableItems?.map(item =>
                generateSKU(item.equipmentCategory, item.equipmentClass)
            );
            const itemsChanged =
                availableCartItems?.filter(item => unavailableItemsSku?.find(sku => sku === item?.product?.sku)) || [];
            return itemsChanged?.map(item => ({
                product: item?.product,
                tileStatus: TILE_STATES.UNAVAILABLE
            }));
        }
        return [];
    };

    const validateCartDetails = async ({
        isCheckout,
        chronosStores,
        availableCartItems = cart?.availableCartItems,
        updateEstimatesFromDeliverySourcePc,
        isDelivery
    }) => {
        const { storesData, isErrorFromAvs, avsAddressDetails } = await computeChronosStoreWithAVS(
            chronosStores,
            availableCartItems,
            isDelivery
        );
        if (isErrorFromAvs) {
            if (avsAddressDetails?.addrResult === 3) {
                // resetting sources data to call again when there is multiple suggestions from avs
                setSortedChronosPcs(null);
            }
            return { isValid: false, isErrorFromAvs };
        }
        if (storesData?.storesError || !storesData?.data?.data) {
            return { isValid: false, isStoreUnavailableOrError: true };
        }
        if (!chronosStores) {
            setSortedChronosPcs(getMergedSourcesATPStores(storesData?.data?.data, storesData?.data?.data));
        }
        const unavailableItems = getUnavailableDeliveryItemsFromSources(storesData?.data?.data, availableCartItems);
        if (unavailableItems?.length > 0) {
            if (unavailableItems?.length === availableCartItems?.length) {
                // if all items are unavailable from sources
                return { isValid: false, isStoreUnavailableOrError: true };
            }
            // if few items are unavailable from sources
            return {
                isValid: false,
                unavailableItemsFromSourcesDetails: unavailableItems
            };
        } else if (
            isDelivery &&
            (selectedStoreDetails?.pc !== storesData?.data?.data?.[0]?.pc || !chronosStores) &&
            updateEstimatesFromDeliverySourcePc
        ) {
            // if sources gave different pc for delivery and items availabilities are not changed
            // or chronosStores is not present -> when we trigger sources again for items availability change
            updateEstimatesFromDeliverySourcePc(storesData?.data?.data?.[0], availableCartItems);
        }
        const { isValid, nextAvailableSlot, endDateError, pickupSlotUnavailableDate } = await validateDateSlots({
            // windows call
            isCheckout,
            storesData,
            availableCartItems,
            avsAddressDetails,
            isDelivery
        });
        return {
            isValid,
            nextAvailableSlot,
            endDateError,
            pickupSlotUnavailableDate
        };
    };

    const showCheckoutButton = () => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            return true;
        }
        if (userType === USER_TYPE.GUEST || userType === USER_TYPE.CASH) {
            return true;
        } else if (userProfile?.type === EMPLOYEE) {
            return false;
        } else {
            const currentAccount = userProfile?.accounts?.find(account => account?.id === userAccount?.accountNumber);
            return currentAccount?.permission?.[0]?.reservation === true;
        }
    };

    const updateFulfillmentToPickup = storeSelected => {
        try {
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.SELECT_STORE_FROM_CONTEXT, true);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, true);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS, JSON.stringify(storeSelected));
            handleChange(VIEW_CART.IN_STORE, true);
            dispatch({
                type: SET_CART_ITEM_STATUS_CHANGED_REASON,
                cartItemsAvailabilityChangedReason: VARIABLE_CONFIG.CART_ITEM_STATUS_CHANGED_REASON.PLACE_INSTORE_PICKUP
            });
            sessionStorage.setItem(
                STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS_AVAILABILITY_CHANGED_REASON,
                VARIABLE_CONFIG.CART_ITEM_STATUS_CHANGED_REASON.PLACE_INSTORE_PICKUP
            );
        } catch (error) {
            logError(error, false, 'updateFulfillmentToPickup');
        }
    };

    /**
     * this function calculates and return items availability for chronos stores including ATP.
     * @param {*} chronosStores
     */
    const getCartItemsForChronosStoresWithAtp = chronosStores => {
        try {
            const cartItemsForChronosStoresWithAtp = {}; // will store all the cart items status for each chronos store
            const conditionalAndUnavailableItemsPerStore = {}; // will store only conditional and unavailable items for each chronos store
            chronosStores.forEach(chronosStore => {
                const cartItems = getCartItemsFromATPAndSources({
                    chronosStores,
                    selectedChronosStore: chronosStore,
                    isPickup: true
                });
                cartItemsForChronosStoresWithAtp[chronosStore?.pc] = cartItems;
                const conditionalAndUnavailableItems = [
                    ...(cartItems?.conditionalItems || []),
                    ...(cartItems?.unavailableItems || [])
                ];
                conditionalAndUnavailableItemsPerStore[chronosStore?.pc] = conditionalAndUnavailableItems?.map(
                    item => item?.product
                );
            });
            return { cartItemsForChronosStoresWithAtp, conditionalAndUnavailableItemsPerStore };
        } catch (error) {
            logError(error, false, 'getCartItemsForChronosStoresWithAtp');
        }
    };

    return {
        fetchCartForCheckout,
        handleRemoveItem,
        handleViewSimilar,
        handleCartQuantityUpdation,
        renderEmptyRentalLabels,
        renderCartItems,
        initialiseSavedCart,
        recomputeEstimates,
        sortedChronosPcs,
        getSelectedAndSuggestedStores,
        handleChange,
        showCheckoutButton,
        onCheckoutHandler,
        isCheckoutDetailsValidating,
        showDiscrepencyStoreSelector,
        updateItemsFromSelectedChronosStore,
        renderCheckoutWithoutValidations,
        getCartItems,
        isWithin72Hours,
        handleChronosStores,
        getCartItemsForChronosStoresWithAtp,
        updateFulfillmentToPickup,
        saveCartItemsInStorage,
        fetchChronosForInStorePickup
    };
};
