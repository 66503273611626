import { useCartState } from '../../contexts/cart';
import { useQuotesState } from '../../components/quotes/context';
import useQuoteSummary from '../../components/molecules/orderSummaryFactory/hooks/useQuoteSummary';
import { isValidString } from '../../components/global/utils/logger';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../constants/storageConfig';

const useP2PAnalytics = () => {
    const [{ orderSummaryDetails }] = useCartState();
    const [{ quoteDetailsData }] = useQuotesState();
    const { quoteSummary } = useQuoteSummary();

    const payloadEditQuoteActionAnalytics = quoteData => {
        const ecommerce = {
            type: VARIABLE_CONFIG.QUOTE.TYPE,
            currency:
                quoteData?.data?.companyId == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            rent_value: quoteSummary?.rentalSubtotal,
            purchase_value: quoteSummary?.purchaseSubtotal,
            delivery_method: quoteData?.deliveryMethod?.toLowerCase(),
            delivery_fee: quoteSummary?.deliveryPickUpCharges,
            value: quoteSummary?.estimatedSubtotal
        };
        return { quote_id: quoteData?.displayQuoteId, ecommerce };
    };

    const payloadQuoteDetailsPageLoadActionAnalytics = () => {
        const quoteData = {
            quote_id: quoteDetailsData?.data?.displayQuoteId,
            ecommerce: {
                type: VARIABLE_CONFIG.QUOTE.TYPE,
                currency:
                    quoteDetailsData?.data?.companyId === '01'
                        ? VARIABLE_CONFIG.CURRENCY_CODE.USA
                        : VARIABLE_CONFIG.CURRENCY_CODE.CANADA,
                rent_value: quoteSummary?.rentalSubtotal,
                purchase_value: quoteSummary?.purchaseSubtotal,
                delivery_method: quoteDetailsData?.data?.deliveryMethod,
                delivery_fee: quoteSummary?.deliveryPickUpCharges,
                value: quoteSummary?.estimatedSubtotal // total value
            }
        };
        return { ...quoteData };
    };

    const payloadSearchAndViewEventsForPurchaseOrderList = (searchQuery, numberOfResults, pOFilterData) => {
        const filters = pOFilterData?.map(item => [item?.key, item?.values]);
        const searchData = {
            search_term: searchQuery,
            number_of_results: numberOfResults,
            filters: Object.fromEntries(filters)
        };
        return { ...searchData };
    };

    const createPayloadTransmitQuotePageLoadActionAnalytics = (action, quoteId) => {
        const deliveryMethod = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
            ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
            : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY;
        const transmitQuoteData = {
            ecommerce: {
                type: VARIABLE_CONFIG.QUOTE.TYPE,
                currency:
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                        ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                        : VARIABLE_CONFIG.CURRENCY_CODE.USA,
                rent_value: orderSummaryDetails?.rentalSubtotal,
                purchase_value: orderSummaryDetails?.purchaseSubtotal,
                delivery_method: deliveryMethod,
                delivery_fee:
                    deliveryMethod === VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY
                        ? orderSummaryDetails?.deliveryPickUpCharges
                        : 0,
                value: orderSummaryDetails?.estimatedSubtotal // total value
            }
        };
        if (quoteId) {
            transmitQuoteData.quote_id = quoteId;
        }
        if (action) {
            transmitQuoteData.action = action;
        }
        return transmitQuoteData;
    };

    const payloadSearchAndViewEventsForQuoteList = (searchQuery, numberOfResults, quotesFilterData) => {
        const searchData = {
            search_term: searchQuery,
            number_of_results: numberOfResults,
            filters: quotesFilterData
        };
        return { ...searchData };
    };

    return [
        {
            createPayloadTransmitQuotePageLoadActionAnalytics,
            payloadEditQuoteActionAnalytics,
            payloadSearchAndViewEventsForPurchaseOrderList,
            payloadSearchAndViewEventsForQuoteList,
            payloadQuoteDetailsPageLoadActionAnalytics
        }
    ];
};

export default useP2PAnalytics;
