import moment from "moment-timezone";
import axiosInstance from '../../../../api/axiosDotComInstance';
import { sentenceCase } from '../../../global/utils/commonUtils';
import { isValidString } from '../../../global/utils/logger';
import config from '../../../App/config';
import { API_CONFIG } from '../../../../constants/apiConfig';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';

const {
    GET_DATE_SLOT_AVAILABILITY_URL,
    GET_TIME_ZONE_URL,
    AVS_VALIDATION_URL,
    GET_PAYMENTS_URL,
    GET_PAYMENT_SESSION_URL,
    GET_ADDPAYMENTS_URL,
    PAYMENT_TOKEN_API_URL,
    ESTIMATES_V2_URL
} = API_CONFIG;

const getCustomAccountsUrl = (accountNumber, corpAccountNumber) =>
    `/web/api/v1/customers/${accountNumber}/customFields?corpAccountNumber=${corpAccountNumber}`;
const getDateSlotAvailability = async (
    companyId,
    latitude,
    longitude,
    slotDate,
    equipments,
    isDelivery,
    zipCode,
    isLargeEquipment,
    currentOffSet,
    storePC,
    isExpressDelivery
) => {
    try {
        const { data, error } = await axiosInstance.post(GET_DATE_SLOT_AVAILABILITY_URL, {
            companyId,
            timeType: 'Delivery',
            isDelivery,
            windowDate: slotDate
                ? moment(slotDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                : moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
            currentDateTime: moment(currentOffSet).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
            latitude,
            longitude,
            zipCode,
            expressDelivery: isExpressDelivery,
            overrideCheckoutPc: true,
            storeId: storePC,
            products: equipments,
            isLargeEquipment
        });
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getTimezoneAPI = async (address, location) => {
    const timestamp = moment().unix();
    try {
        const { data, error } = await axiosInstance.get(GET_TIME_ZONE_URL, {
            params: {
                address,
                timestamp: timestamp
            }
        });
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getPaymentAPI = async () => {
    try {
        const userProfile = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE));
        // const paymentHeaders = {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //     clientKey: '3dfbdf465f24483d8be8e9af7dc5396c',
        //     clientSecret: '227FB4b65bC74503856fF01D051e79a4',
        //     client_secret: '227FB4b65bC74503856fF01D051e79a4',
        //     client_id: '3dfbdf465f24483d8be8e9af7dc5396c',
        //     'Request-Id': '12323sunbelt',
        //     channel: 'B2C',
        //     companyId: '0' + userProfile.companyID,
        //     loginToken: 'e21bb91b-b3da-48c5-b2c9-4b10c6fbd37e',
        //     guid: userProfile.userGuid,
        //     Authorization:
        //         'Bearer eyJraWQiOiJNazNURzNLelM5QUNIR2Z1Rk52Rm9XNjNYTTRCS2tab0hZazNmT2hSVGprIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmM5QURQWEl5bUdNM2RxQ2M0ejhDQnZpZW85eXFhd2dRR1gzT3pVVXB4eXcub2FyODJxenRtSVd0S2tycWgxZDYiLCJpc3MiOiJodHRwczovL3N1bmJlbHRyZW50YWxzLWNpYW0ub2t0YXByZXZpZXcuY29tL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTYzMzcwMzc1NSwiZXhwIjoxNjMzNzA0NjU1LCJjaWQiOiIwb2E5djZyNzM4QXFLdFVZZTFkNiIsInVpZCI6IjAwdW15cTA5cDFRSkRIN2paMWQ2Iiwic2NwIjpbIm9mZmxpbmVfYWNjZXNzIiwib3BlbmlkIl0sInN1YiI6ImIyYmpvYnNpdGV1c2VyMTFAeW9wbWFpbC5jb20iLCJzd3NfZ3VpZCI6ImRlZTBjMDBjLTRhNzMtNDE5Ni05MDY1LTU2Y2VjZDlmNjdmMiJ9.fbyc-naG5TjWYrFubX12anGlCJ8w_LTrdZECiSqoS-jFr_vhS2bpTfd-SNjRRx5iFA6_YLzRNyUj0eucwweCGy4UauXN1vnV-gQTPMY31WEbsPOcBHjP-s6BT1yjEMMCDDBVtYkG5y2FQ53I2w2slmDDQJF7ZZqRkqQAfhYovlLF8Cs4M2IQzur5Gg-s0cjkm6JzVOKI_B2zxdo3dwRPV6HDhsjPIDNrOyvq4_4MJr8dqa2a8OLZibXj4F1g4It5arSKNciB5DiikJRG59bDZ1DtCbw3THHex4avhEigwPAFS0t-XWFkm-S-q0IqRGum-S3xgj_spwmxaJD2UiSKkg'
        // };
        const { data, error } = await axiosInstance.post(
            GET_PAYMENTS_URL,
            {
                dlNumber: userProfile?.dlNumber?.trim() || sessionStorage.getItem('dlNumberGuestUser'),
                dlState: userProfile?.dlState?.trim() || sessionStorage.getItem('dlStateGuestUser')
            },
            {
                headers: {
                    guid: userProfile?.userGuid || 'b0c99754-cf1d-4301-8720-1ebbebd57241'
                }
            }
        );
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getPaymentSessionAPI = async (customerID, userType) => {
    try {
        const checkoutURL =
            parseInt(localStorage.getItem('companyID')) === 2
                ? config?.pagePaths?.checkoutPageCA
                : config?.pagePaths?.checkoutPageUS;
        const { data, error } = await axiosInstance.post(
            GET_PAYMENT_SESSION_URL,
            {
                customerId: customerID,
                referURL: window.location.origin + checkoutURL,
                showCheckbox: userType !== USER_TYPE.GUEST ? 'TRUE' : 'FALSE',
                checkBoxDefault: userType !== USER_TYPE.GUEST ? 'TRUE' : 'FALSE'
            },
            {
                headers: {
                    'company-id': '0' + (parseInt(localStorage.getItem('companyID')) || 1),
                    'x-correlation-Id': 'test-123-get-call-222',
                    channel: userType === USER_TYPE.CREDIT ? 'b2b' : 'b2c'
                }
            }
        );
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getAVSValidationAPI = async addressObj => {
    try {
        const { data, error } = await axiosInstance.post(AVS_VALIDATION_URL, {
            address1: addressObj.streetAddress,
            address2: addressObj.selectedAddress2,
            city: addressObj.selectedCity,
            state: addressObj.selectedState,
            zip: addressObj.selectedZip,
            type: 'delivery'
        });
        const avsResponseData = data?.data?.addrList;
        if (avsResponseData) {
            avsResponseData.forEach((item, index) => {
                for (let item2 in item) {
                    switch (item2) {
                        case 'address1':
                            data.data.addrList[index].address1 = sentenceCase(item.address1);
                            break;
                        case 'address2':
                            data.data.addrList[index].address2 = sentenceCase(item.address2);
                            break;
                        case 'city':
                            data.data.addrList[index].city = sentenceCase(item.city);
                            break;
                        default:
                            break;
                    }
                }
            });
        }
        return { data, error };
    } catch (error) {
        return { error };
    }
};
//
const getAddPaymentAPI = async (saveCardStatus) => {
    try {
        const userProfile = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE));
        const { data, error } = await axiosInstance.post(
            GET_ADDPAYMENTS_URL,
            {
                // paymentToken: reptoken,
                custIdent: sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CUSTIDENT),
                emailId: userProfile?.email || JSON.parse(sessionStorage.getItem('guestUserEmail')),
                statusAfterTransaction: saveCardStatus ? 'A' : 'D',
                defaultValue: 'N',
                status: 'A',
                ipAddress:
                    isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.IP_ADDRESS)) ||
                    ENV_CONFIG.IP_ADDRESS_DEFAULT,
                dlNumber: userProfile?.dlNumber?.trim() || sessionStorage.getItem('dlNumberGuestUser'),
                dlState: userProfile?.dlState?.trim() || sessionStorage.getItem('dlStateGuestUser')
            },
            {
                headers: {
                    guid: userProfile?.userGuid || 'b0c99754-cf1d-4301-8720-1ebbebd57241'
                }
            }
        );
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getCustomAccountFields = async (accountNumber, corpAccountNumber) => {
    try {
        const { data, error } = await axiosInstance.get(getCustomAccountsUrl(accountNumber, corpAccountNumber), {
            headers: {
                'company-id': parseInt(localStorage.getItem('companyID')) || 1,
                'x-correlation-Id': 'test12345'
            }
        });
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getTokenAPI = async () => {
    try {
        const { data, error } = await axiosInstance.get(PAYMENT_TOKEN_API_URL, {
            headers: {
                custIdent: sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CUSTIDENT),
                // 'payment-token': reptoken,
                'customer-type': 'b2c'
            }
        });
        return { data, error };
    } catch (error) {
        return { error };
    }
};

const getRentablesPrice = async (payload) => {
    try {
        const { data, error } = await axiosInstance.post(ESTIMATES_V2_URL, payload);
        if (error) return error;
        else return data;
    } catch (error) {
        throw new Error(error);
    }
};

export {
    getAVSValidationAPI,
    getAddPaymentAPI,
    getCustomAccountFields,
    getDateSlotAvailability,
    getPaymentAPI,
    getPaymentSessionAPI,
    getRentablesPrice,
    getTimezoneAPI,
    getTokenAPI
};
