import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { useDidMount } from '../../../../hooks/useDidMount';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import HideIcon from '../../../../resources/images/hide.svg';
import ShowEyeIcon from '../../../../resources/images/showeye.svg';
import Button from '../../atoms/button';
import MapLocationMarker from '../mapLocationMarker';
import MobileView from '../mobileView/MobileView';
import { logError } from '../../utils/logger';
import StoreDetails from '../../../checkoutv2/StoreDetails';
import StoreModal from '../../../checkoutv2/StoreModal';
import '../../../checkoutv2/howToGetYourOrder/howToGetOrder.scss';
import Close from '../../../../resources/images/close.svg';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Page';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { getDirectionCTA, getSelectedlatLong } from '../../../checkoutv2/utils/editModuleFunctions';
import Stores from '../Stores/Stores';
import SideDrawerModal from '../modals/SideDrawerModal';
import { bool, func, string } from 'prop-types';
import './ChooseAStoreView.scss';
import { STORE_VARIANT } from '../../../cap/constants';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';

export const ChooseAStoreView = props => {
    const intl = useIntl();
    const didMount = useDidMount();
    const {
        makeSelectedClicked,
        handleBackClickMobile,
        mobileCloseButton,
        onStoreDetailsClick,
        mapToggle,
        storesData,
        selectedStoreValue,
        setSelectedStoreValue,
        storeDetailData,
        mediaType,
        setIsBestMatch,
        isBestMatch,
        handleToggle,
        handleChooseThisStoreClick,
        onStoreDetailClick,
        saveStoreDetails,
        setOnStoreDetailsClick,
        handleChooseStoreFromStoreDetails,
        handleMapToggle,
        handleCloseClick,
        nextAvailableStartDate,
        cartState,
        analyticEvents,
        payloadEcommerceLocationActionAnalytics,
        pickupStoreFooterClass,
        pickupStoreHeaderClass,
        mapMobileClass,
        variant,
        pickupStoreDrawerHeading,
        pickupStoreDrawerFooter,
        useFilterState,
        currentLocation,
        showAvailableStartDate,
        showInDrawerForTablet,
        showMonochromaticMap,
        storeDetailsClassName,
        storesClassName,
        companyId,
        triggerStoreModalAnalytics,
        showShimmer,
        localUnavailableCartItemsPerPc,
        showDrawerInRight,
        localStartDate
    } = props;
    const userType = useCheckUser();
    const { sendEventsForClick, sendEventsForPageUser, sendEventsForEcommercePage } = analyticEvents;
    const [{ viewCart, projectDetails }] = useFilterState();
    const callEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
            triggerStoreModalAnalytics &&
                triggerStoreModalAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_SET_PC_VIEWED);
        } catch (error) {
            logError(error, false, 'callEvents');
        }
    };
    useEffect(() => {
        didMount && callEvents();
    }, [didMount]);

    const renderTabletView = () => {
        const content = () => (
            <>
                {mapToggle ? renderMapMarkers(false) : ''}
                {renderStoresView()}
            </>
        );
        return (
            <SideDrawerModal
                isModalOpen
                modalHeaderClass={pickupStoreHeaderClass}
                modalContentClass={pickupStoreFooterClass}
                modalFooterClass="modal-map-rightfooter"
                handleModalToggle={handleCloseClick}
                header={pickupStoreDrawerHeading()}
                footer={pickupStoreDrawerFooter()}
                content={content()}
                showModalInRight={showDrawerInRight}
            />
        );
    };

    const handleOnPressDirection = () => {
        getDirectionCTA(storeDetailData, storesData, viewCart, userType, projectDetails);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0].pc} ${VARIABLE_CONFIG.EVENT_LABEL.GET_DIRECTIONS}`
            );
        } catch (error) {
            logError(error, false, 'handleOnPressDirection');
        }
    };

    const renderStoresView = () => {
        return (
            <>
                {!onStoreDetailsClick ? (
                    <>
                        <Stores
                            storesData={storesData}
                            setIsBestMatch={setIsBestMatch}
                            isBestMatch={isBestMatch}
                            handleToggle={handleToggle}
                            setSelectedStoreValue={setSelectedStoreValue}
                            selectedStoreValue={selectedStoreValue ? selectedStoreValue : storesData?.data[0]?.pc || ''}
                            handleChooseThisStoreClick={handleChooseThisStoreClick}
                            onStoreDetailClick={onStoreDetailClick}
                            onPressRadioButton={saveStoreDetails}
                            nextAvailableStartDate={nextAvailableStartDate}
                            onStoreDetailsClick={onStoreDetailsClick}
                            handleCloseClick={handleCloseClick}
                            setOnStoreDetailsClick={setOnStoreDetailsClick}
                            variant={variant}
                            mediaType={mediaType}
                            showAvailableStartDate={showAvailableStartDate}
                            className={storesClassName}
                            companyId={companyId}
                            showShimmer={showShimmer}
                            localUnavailableCartItemsPerPc={localUnavailableCartItemsPerPc}
                            localStartDate={localStartDate}
                        />
                    </>
                ) : (
                    <StoreDetails
                        onPressGetDirection={handleOnPressDirection}
                        storeDetailData={storeDetailData || storesData?.data[0]}
                        setOnStoreDetailsClick={setOnStoreDetailsClick}
                        handleChooseThisStoreClick={handleChooseStoreFromStoreDetails}
                        variant={variant}
                        mediaType={mediaType}
                        className={storeDetailsClassName}
                        companyId={companyId}
                        hideFooterButtons={variant == STORE_VARIANT.CAP_HEADER && mediaType === MEDIA_TYPE.TABLET}
                        showBackHeader={variant == STORE_VARIANT.CAP_HEADER && mediaType === MEDIA_TYPE.DESKTOP}
                    />
                )}
            </>
        );
    };
    const renderMapMarkers = isMobileView => {
        return (
            <div className={isMobileView ? `${'mapStoresBoxMobile'} ${mapMobileClass}` : `${'mapStoresBox'}`}>
                <MapLocationMarker
                    selectedLatLong={getSelectedlatLong(storesData, selectedStoreValue)}
                    defaultCenter={{
                        lat: storesData?.data[0]?.latitude,
                        long: storesData?.data[0]?.longitude
                    }}
                    onClickMarker={item => {
                        setSelectedStoreValue(item);
                    }}
                    selectedStoreValue={selectedStoreValue ? selectedStoreValue : storesData?.data[0]?.pc || ''}
                    storesData={!onStoreDetailsClick ? storesData : storeDetailData || storesData?.data[0]}
                    onStoreDetailsClick={onStoreDetailsClick}
                    saveStoreDetails={saveStoreDetails}
                    currentLocation={currentLocation}
                    showMonochromaticMap={showMonochromaticMap}
                />
            </div>
        );
    };
    return (
        <>
            {mediaType === 'MOBILE' ? (
                <MobileView
                    rootClass={`mobile-view-root`}
                    isOpen={makeSelectedClicked}
                    onRequestClose={handleCloseClick}
                    handleClose={handleBackClickMobile}
                    footerContent={mobileCloseButton}
                    isChooseStoreView={true}
                    customHeader={variant == STORE_VARIANT.CAP_HEADER && pickupStoreDrawerHeading()}
                    customHeaderWrapClass={variant == STORE_VARIANT.CAP_HEADER && pickupStoreHeaderClass}>
                    {!onStoreDetailsClick && !variant == STORE_VARIANT.CAP_HEADER && (
                        <button className={`${'mapButton'} button`} onClick={handleMapToggle} tabIndex={'0'}>
                            {mapToggle ? (
                                <>
                                    <HideIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Hide map{' '}
                                </>
                            ) : (
                                <>
                                    <ShowEyeIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Show map{' '}
                                </>
                            )}
                        </button>
                    )}
                    {mapToggle ? renderMapMarkers(true) : ''}
                    {renderStoresView()}
                </MobileView>
            ) : mediaType === MEDIA_TYPE.TABLET && showInDrawerForTablet ? (
                renderTabletView()
            ) : (
                <StoreModal
                    modalDialogClass={'modal-map-wrap'}
                    isChooseStoreView={true}
                    onRequestClose={handleCloseClick}>
                    <div className="modal-map">
                        {renderMapMarkers(false)}
                        {!onStoreDetailsClick ? (
                            <Button
                                tabIndex={0}
                                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                className="modal-map-rightheader-cross"
                                onClick={() => handleCloseClick(true)}>
                                <Close tabIndex={'-1'} aria-hidden={true} />
                            </Button>
                        ) : (
                            <Button
                                tabIndex={0}
                                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                className="modal-map-rightheader-cross store-details-cross"
                                onClick={() => setOnStoreDetailsClick(false)}>
                                <Close tabIndex={'-1'} aria-hidden={true} />
                            </Button>
                        )}
                        {renderStoresView()}
                    </div>
                </StoreModal>
            )}
        </>
    );
};

ChooseAStoreView.propTypes = {
    setIsBestMatch: func,
    handleBackClickMobile: func,
    mobileCloseButton: func,
    handleToggle: func,
    variant: string,
    showInDrawerForTablet: bool,
    showMonochromaticMap: bool,
    storesClassName: string,
    storeDetailsClassName: string,
    triggerStoreModalAnalytics: func,
    showShimmer: bool,
    showDrawerInRight: bool
};
ChooseAStoreView.defaultTypes = {
    setIsBestMatch: () => {},
    handleBackClickMobile: () => {},
    mobileCloseButton: () => {},
    handleToggle: () => {},
    variant: '',
    showAvailableStartDate: false,
    showInDrawerForTablet: false,
    showMonochromaticMap: false,
    storesClassName: '',
    storeDetailsClassName: '',
    triggerStoreModalAnalytics: () => {},
    showShimmer: false,
    showDrawerInRight: false
};
export default memo(ChooseAStoreView);
